import * as React from "react"
import { Link } from "gatsby"
import Dar from "@mui/icons-material/DoubleArrow"
import Layout from "../components/layout"
import Seo from "../components/seo"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "Home",
  },
  {
    id: 3,
    ink: "/competitor-comparison/",
    label: "Competitor Comparison",
  },
]

const CompetitorComparison = () => (
  <Layout>
    <Seo title="Competitor Comparison" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <section className="section-analysis grid--bg --competitor">
      <div className="container">
        <div className="row">
          <div className="section-col-1 col-sm-5">
            <h6>COMPETITIVE ANALYSIS</h6>
            <hr />
            <h1>
              VIGILANT VS. <br />
              COMPETITORS
            </h1>
            <h5>
              Taking and owning the higher ground <br />
              is better than just defending it
            </h5>
            <p>
              Vigilant’s collaborative approach goes beyond defending with
              world-class detection tools as well.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div>
      <section className="section-quad-btn">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>COMPARE SOLUTIONS</h3>
              <ul className="compare-list">
                <li>
                  <a
                    href="https://sp1.sdcdn.app/pdf/Vigilant_Arctic-Wolf_Battlecard.pdf"
                    alt="VIGILANT VS. ARCTIC WOLF"
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    <h5>VIGILANT VS. ARCTIC WOLF</h5>
                  </a>
                </li>

                <li>
                  <a
                    href="https://sp1.sdcdn.app/pdf/Vigilant_Esentire_Battlecard.pdf"
                    alt="VIGILANT VS. ESENTIRE"
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    <h5>VIGILANT VS. ESENTIRE</h5>
                  </a>
                </li>

                <li className="w-100">
                  <a
                    href="https://vigilant.sdcdn.app/pdf/vigilant_reliaquest_battlecard.pdf"
                    alt="VIGILANT VS. RELIAQUEST"
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    <h5>VIGILANT VS. RELIAQUEST</h5>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section className="section-title--competitors">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>WHY VIGILANT IS BETTER THAN TOP COMPETITORS</h3>
              <hr />
            </div>
            <div className="section-col-2">
              <div className="icon-row">
                <div className="icon-row--media">
                  <img
                    src="../images/competitor-comparison/logo1.png"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Logo Holder"
                    className="img-fluid"
                  />
                </div>
                <div className="icon-row--content">
                  <p>
                    VigilantMDR provides Unlimited Breach Response services at
                    no cost, which is unique to this industry. So you never have
                    to worry about uncontrollable costs going up due to the
                    number of IRs.
                  </p>
                </div>
              </div>
              <div className="icon-row">
                <div className="icon-row--media">
                  <img
                    src="../images/competitor-comparison/logo2.png"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Logo Holder"
                    className="img-fluid"
                  />
                </div>
                <div className="icon-row--content">
                  <p>
                    Vigilant’s source content is simply the best. We collect raw
                    source data to create our proprietary detection logic which
                    spurs our approach to proactive threat hunting. This service
                    is far superior to those who merely capture log or alerted
                    data. The result is a significant increase in data fidelity
                    that allows us to detect at deeper levels with the utmost
                    accuracy.
                  </p>
                </div>
              </div>
              <div className="icon-row">
                <div className="icon-row--media">
                  <img
                    src="../images/competitor-comparison/logo3.png"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Logo Holder"
                    className="img-fluid"
                  />
                </div>
                <div className="icon-row--content">
                  <p>
                    Vigilant’s own research and offensive tactics and techniques
                    informs each threat hunting mission. Our expert threat
                    hunters are the steadfast sentinels that protect you and
                    your company’s precious assets with an approach that is
                    complete and full-scope. We keep a secure 24/7/365 US-based
                    SOC. And for added peace of mind, you can rest assured that
                    Vigilant will never sell your data or reveal your identity.
                    Ever.
                  </p>
                </div>
              </div>
              <div className="icon-row">
                <div className="icon-row--media">
                  <img
                    src="../images/competitor-comparison/logo4.png"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Logo Holder"
                    className="img-fluid"
                  />
                </div>
                <div className="icon-row--content">
                  <p>
                    You will get to know your Hunt Team personally and be
                    assigned a Client Success Manager for all things related to
                    your MDR service. In fact, you can always count on a
                    strategic conversation with a Vigilant Analyst every 30
                    days.
                  </p>
                </div>
              </div>
              <div className="icon-row">
                <div className="icon-row--media">
                  <img
                    src="../images/competitor-comparison/logo5.png"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Logo Holder"
                    className="img-fluid"
                  />
                </div>
                <div className="icon-row--content">
                  <p>
                    Vigilant’s verification visibility provides real-time
                    insight as to what’s happening in your environment while
                    radically visualizing all of the changes implemented.
                    Combined with our source content, the result is a confident
                    and coordinated confirmed response, which keeps attacks from
                    happening.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box-cta">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h2>
                  Don’t Just Take Our Word for it,{" "}
                  <br className="d-none d-lg-block" />
                  Hear What Our Clients Have to Say
                </h2>
                <hr />
                <Link to="/client-stories/" className="nav-section--cta-btn">
                  <div className="d-flex align-items-center">
                    <span
                      style={{ marginLeft: "25px" }}
                      className="nav-section--cta-title pr-5"
                    >
                      view client success stories
                    </span>
                    <Dar
                      className="chevy"
                      sx={{ fontSize: 15, marginLeft: 2 }}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default CompetitorComparison
